import selectArrow from "../../../images/select-arrow.png";

export default theme => ({
    subCatPage: {
        marginTop: 36,
        '& .top-bar': {
            marginBottom: 10,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& p': {
                margin: 0,
                fontSize: 16,
                letterSpacing: '0.016em',
                lineHeight: '36px',
                color: 'rgba(15,15,15,0.5)',
                fontFamily: theme.headerFont,
                marginBottom: 15,
            },
            '& .orderBy': {
                marginLeft: 'auto',
                marginRight: 0,
            },
            '& .itemToShow': {
                marginLeft: 15,
            },
            '& .sort, & .itemToShow, & .orderBy': {
                marginBottom: 15,
                '& label': {
                    fontSize: 16,
                    letterSpacing: '0.016em',
                    lineHeight: '36px',
                    color: 'rgba(15,15,15,0.5)',
                    fontFamily: theme.headerFont,
                    paddingRight: 10,
                },
                '& select': {
                    fontSize: 12,
                    padding: '10px 12px',
                    letterSpacing: '0.016em',
                    fontFamily: 'Overpass, sans-serif',
                    color: theme.palette.primary.main,
                    border: 'none',
                    paddingRight: 50,
                    borderRadius: 18,
                    outline: 'none',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                    '-webkit-appearance': 'none',
                    '-moz-appearance': 'none',
                    backgroundColor: 'white',
                    backgroundImage: 'url(' + selectArrow + ')',
                    backgroundPosition: 'center right 10px',
                    backgroundRepeat: 'no-repeat',
                    transform: 'translateY(-3px)',
                }
            }
        }
    }
});