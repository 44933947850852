import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";

import { clearProducts, removeProduct, showComparison } from "./app";

import styles from "./Picker.styles";

const useStyles = makeStyles((theme) => styles(theme));

export default function Picker() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.CompareAppSlice);

  const firstProduct = products[0];
  const secondProduct = products[1];

  if (!firstProduct && !secondProduct) {
    return null;
  }

  return (
    <div className={classes.compare}>
      <div className="wrapper">
        <h2 className="mobile-title">Select up to two products</h2>

        {firstProduct && (
          <div className="product">
            <button onClick={() => dispatch(removeProduct(firstProduct.id))}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.169"
                height="12.17"
                viewBox="0 0 12.169 12.17"
              >
                <path
                  d="M3258.992,750.544a2.067,2.067,0,0,1-1.472-.61l-2.531-2.53-2.53,2.53a2.083,2.083,0,0,1-2.944-2.946l2.529-2.53-2.529-2.53a2.083,2.083,0,0,1,2.944-2.946l2.53,2.53,2.531-2.53a2.083,2.083,0,0,1,2.946,2.946l-2.531,2.53,2.531,2.53a2.083,2.083,0,0,1-1.473,3.556Zm-4-3.978Z"
                  transform="translate(-3248.905 -738.374)"
                  fill="#c8102e"
                />
              </svg>
            </button>
            <img
              src={firstProduct.product.productGallery[0].image}
              width={200}
              height={200}
              alt={``}
            />
            <div className="data">
              <p className="sku">{firstProduct.product.code}</p>
              <h3 className="title">{firstProduct.title}</h3>
            </div>
          </div>
        )}

        {!secondProduct && (
          <div className="add">
            <svg width="17.211" height="17.211" viewBox="0 0 17.211 17.211">
              <g transform="translate(0 8.605) rotate(-45)" opacity="0.4">
                <path
                  d="M3258.992,750.544a2.067,2.067,0,0,1-1.472-.61l-2.531-2.53-2.53,2.53a2.083,2.083,0,0,1-2.944-2.946l2.529-2.53-2.529-2.53a2.083,2.083,0,0,1,2.944-2.946l2.53,2.53,2.531-2.53a2.083,2.083,0,0,1,2.946,2.946l-2.531,2.53,2.531,2.53a2.083,2.083,0,0,1-1.473,3.556Zm-4-3.978Z"
                  transform="translate(-3248.905 -738.374)"
                  fill="#0f0f0f"
                />
              </g>
            </svg>
            Add Product
          </div>
        )}

        {secondProduct && (
          <div className="product">
            <button onClick={() => dispatch(removeProduct(secondProduct.id))}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.169"
                height="12.17"
                viewBox="0 0 12.169 12.17"
              >
                <path
                  d="M3258.992,750.544a2.067,2.067,0,0,1-1.472-.61l-2.531-2.53-2.53,2.53a2.083,2.083,0,0,1-2.944-2.946l2.529-2.53-2.529-2.53a2.083,2.083,0,0,1,2.944-2.946l2.53,2.53,2.531-2.53a2.083,2.083,0,0,1,2.946,2.946l-2.531,2.53,2.531,2.53a2.083,2.083,0,0,1-1.473,3.556Zm-4-3.978Z"
                  transform="translate(-3248.905 -738.374)"
                  fill="#c8102e"
                />
              </svg>
            </button>
            <img
              src={secondProduct.product.productGallery[0].image}
              width={200}
              height={200}
              alt={``}
            />
            <div className="data">
              <p className="sku">{secondProduct.product.code}</p>
              <h3 className="title">{secondProduct.title}</h3>
            </div>
          </div>
        )}
        <div className="actions">
          <h2 className="desktop-title">Select up to two products</h2>
          <button
            disabled={products.length !== 2}
            onClick={() => dispatch(showComparison())}
            className="compare"
          >
            Compare products
          </button>
          <button onClick={() => dispatch(clearProducts())} className="clear">
            <svg width="12.169" height="12.17" viewBox="0 0 12.169 12.17">
              <path
                d="M3258.992,750.544a2.067,2.067,0,0,1-1.472-.61l-2.531-2.53-2.53,2.53a2.083,2.083,0,0,1-2.944-2.946l2.529-2.53-2.529-2.53a2.083,2.083,0,0,1,2.944-2.946l2.53,2.53,2.531-2.53a2.083,2.083,0,0,1,2.946,2.946l-2.531,2.53,2.531,2.53a2.083,2.083,0,0,1-1.473,3.556Zm-4-3.978Z"
                transform="translate(-3248.905 -738.374)"
                fill="#c8102e"
              />
            </svg>

            <span>Clear all</span>
          </button>
        </div>
      </div>
    </div>
  );
}
