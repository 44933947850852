export default (theme) => ({
  catListing: {
    marginTop: 36,
    "& .cat-link": {
      display: "block",
      border: "20px solid white",
      position: "relative",
      color: "white",
      boxShadow: "0 4px 8px rgba(0,0,0,0.08)",
      transition: "opacity .3s ease-in-out",
      "&:hover, &:focus": {
        opacity: 0.8,
      },
      "& .gatsby-image-wrapper": {
        "& > div": {
          paddingBottom: "100% !important",
          maxHeight: 250,
        },
      },
      "& span": {
        backgroundColor: "white",
        fontFamily: theme.headerFont,
        fontSize: 18,
        letterSpacing: "0.016em",
        position: "absolute",
        bottom: -1,
        right: 40,
        left: -5,
        color: theme.palette.primary.main,
        padding: "10px 12px 5px 5px",
        clipPath:
          "polygon(0% 0%, calc(100% - 32px) 0, 100% 40px, 100% 100%, 0 100%)",
      },
      "& > img": {
        width: 36,
        height: 36,
        position: "absolute",
        bottom: 2,
        right: 2,
        filter: "brightness(0) invert(1)",
      },
    },
  },
});
