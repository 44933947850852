import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";

// import Card from "./Card";

class ProductsGrid extends React.Component {
  render() {
    if (!this.props.subcategories) return null;

    const subcategories = this.props.subcategories;

    return (
      <Grid container spacing={3}>
        {subcategories.map((category, index) => {
          // const { uri, name } = category;
          return (
            <Grid item xs={12} sm={6} lg={4} key={index}>
              <Link to={category.uri} className="cat-link">
                {category.productCategoryExtras.categoryImage !== null && (
                  <Img
                    fluid={
                      category.productCategoryExtras?.categoryImage?.localFile
                        ?.childImageSharp?.fluid
                    }
                  />
                )}
                <span>{ReactHtmlParser(category.name)}</span>
                {category.productCategoryExtras.categoryIcon !== null && (
                  <img
                    src={
                      category.productCategoryExtras?.categoryIcon?.localFile
                        ?.publicURL
                    }
                    alt={``}
                  />
                )}
              </Link>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

export default ProductsGrid;
