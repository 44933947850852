import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import styles from "./SideNav.styles";

import { setFilter, setPage } from "../../../app/actions";
import { isLoggedIn } from "../../../services/auth";

import filterIcon from "../../../images/filter.png";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  filters: state.ShopSettings.filters,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (prevFilters, filter) => dispatch(setFilter(prevFilters, filter)),
  setPage: (page) => dispatch(setPage(page)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function Sidenav({
  id,
  name,
  uri,
  subcategories,
  subsubcategories,
  siblings,
  parent,
  brands,
  filters,
  setFilter,
  setPage,
}) {
  const classes = useStyles();

  const isMainCategory = !parent;
  const isSubCategory =
    parent && !!siblings.length && !!subsubcategories.length;
  const isMinorCategory =
    parent && !!siblings.length && !subsubcategories.length;
  const [authenticated, setAuthentication] = useState(undefined);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    isLoggedIn().then((response) => {
      if (response.data.errors) {
        setAuthentication(false);
      } else {
        setAuthentication(response.data.data.user);

        const group = response.data.data.user.userGroup.group.slug;

        if (group) {
          setGroup(group);
        }
      }
    });
  }, [uri]);

  function priceHandler(e) {
    const { value, name } = e.target;
    if (name === "min") {
      setFilter({ ...filters, [name]: Number(value) });
    }
    if (name === "max") {
      setFilter({ ...filters, [name]: Number(value) });
    }

    setPage(1);
  }

  function filterHandler(brand) {
    if (filters.brand === brand) return setFilter({ ...filters, brand: "" });

    setFilter({ ...filters, brand });
    setPage(1);
  }

  function Brands({ brands, brandsToShow = [] }) {
    return brands.edges
      .map((brand) => brand.node)
      .filter((brand) => brandsToShow.includes(brand.slug))
      .map((brand) => {
        return (
          <button
            key={brand.slug}
            className={`${filters.brand === brand.slug ? "is-active" : ""}`}
            onClick={(e) => filterHandler(brand.slug)}
          >
            {brand.name}
          </button>
        );
      });
  }

  function filterMainCategorySlug(name) {
    if (name.includes("LV")) {
      return name.replace("LV", "").trim();
    }

    if (name.includes("CV")) {
      return name.replace("CV", "").trim();
    }

    return name;
  }

  function resetFilters() {
    setFilter({
      ...filters,
      min: 1,
      max: 12500,
      brand: "",
    });
  }

  if (isMainCategory) {
    const brandsToShow =
      subcategories &&
      subcategories.flatMap((subcategory) =>
        subcategory.products.nodes
          .map((node) => node.product.brand)
          .filter((brand) => brand)
          .map((brand) => brand.slug)
      );

    return (
      <aside className={classes.sideNav}>
        <div className="categories">
          <h3>Categories</h3>
          <span className="current-category">
            {filterMainCategorySlug(name)}
          </span>
          {subcategories ? (
            <ul className="children">
              {subcategories.map((subcategory, index) => {
                const { name, uri } = subcategory;
                const total = subcategory.products.nodes.length;
                if (total > 0) {
                  return (
                    <li key={index} name={name}>
                      <Link to={`${uri}`}>
                        {filterMainCategorySlug(name)} <span>{total}</span>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          ) : null}
        </div>
        <div className="brands">
          <h4>Brands</h4>
          <Brands brands={brands} brandsToShow={brandsToShow} />
        </div>
      </aside>
    );
  }

  if (isSubCategory) {
    const brandsToShow = siblings
      .find((sibling) => sibling.id === id)
      .products.nodes.map((product) => product.product.brand)
      .filter((brand) => brand)
      .map((brand) => brand.slug);

    return (
      <div className={classes.sideNav}>
        <div className="categories">
          <h3>Categories</h3>
          <Link to={parent.uri} className="current-category">
            See all {filterMainCategorySlug(parent.name)}
          </Link>
          <ul className="children">
            {subsubcategories.map((subsubcategory, index) => {
              const { name, uri } = subsubcategory;
              const total = subsubcategory.products.nodes.length;
              if (total > 0) {
                return (
                  <li key={index} name={name}>
                    <Link to={uri}>
                      {filterMainCategorySlug(name)} <span>{total}</span>
                    </Link>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
        <h3 className="filters">
          Filters <img src={filterIcon} alt="" />
        </h3>
        {(group === "trade" || group === "member") && (
          <div className="range">
            <h4>Price Range</h4>
            <div className="inputs">
              <span>From</span>
              <input
                type="number"
                name="min"
                defaultValue={0}
                value={filters.min}
                onChange={priceHandler}
              />
              <span>To</span>
              <input
                type="number"
                name="max"
                defaultValue={12500}
                value={filters.max}
                onChange={priceHandler}
              />
            </div>
          </div>
        )}
        <div className="brands">
          <h4>Brands</h4>
          <Brands brands={brands} brandsToShow={brandsToShow} />
        </div>
        <button className="reset" onClick={(e) => resetFilters()}>
          Reset filters
        </button>
      </div>
    );
  }

  if (isMinorCategory) {
    const brandsToShow = siblings
      .find((sibling) => sibling.id === id)
      .products.nodes.map((product) => product.product.brand)
      .filter((brand) => brand)
      .map((brand) => brand.slug);

    return (
      <div className={classes.sideNav}>
        <div className="categories">
          <h3>Categories</h3>
          <Link to={parent.uri} className="current-category">
            See all {filterMainCategorySlug(parent.name)}
          </Link>
          <ul className="children">
            {siblings.map((subsubcategory, index) => {
              const { name, uri } = subsubcategory;
              const total = subsubcategory.products.nodes.length;
              if (total > 0) {
                return (
                  <li
                    key={index}
                    name={name}
                    className={`${subsubcategory.id === id ? "active" : ""}`}
                  >
                    <Link to={uri}>
                      {filterMainCategorySlug(name)} <span>{total}</span>
                    </Link>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
        <h3 className="filters">
          Filters <img src={filterIcon} alt="" />
        </h3>
        {authenticated && (
          <div className="range">
            <h4>Price Range</h4>
            <div className="inputs">
              <span>From</span>
              <input
                type="number"
                name="min"
                defaultValue={0}
                value={filters.min}
                onChange={priceHandler}
              />
              <span>To</span>
              <input
                type="number"
                name="max"
                defaultValue={12500}
                value={filters.max}
                onChange={priceHandler}
              />
            </div>
          </div>
        )}
        <div className="brands">
          <h4>Brands</h4>
          <Brands brands={brands} brandsToShow={brandsToShow} />
        </div>
        <button className="reset" onClick={(e) => resetFilters()}>
          Reset filters
        </button>
      </div>
    );
  }
}

export default connector(Sidenav);
