import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import Sidenav from '../Components/Sidenav';
import CategoriesGrid from '../Components/CategoriesGrid';
import { makeStyles } from '@material-ui/styles';
import styles from "./Category.styles";

const useStyles = makeStyles(theme => styles(theme));

const mapStateToProps = (state) => ({
  filters: state.ShopSettings.filters,
});

const connector = connect(mapStateToProps, null);

function Category({ id, name, products, wpChildren, brands, filters }) {

  const classes = useStyles();
  const filteredProducts = wpChildren.nodes.map(products => {
    return {
      ...products,
      products: {
        nodes: products.products.nodes.filter(product => {
          if (!filters.brand) return true;
          const brand = product.product.brand && product.product.brand.slug;
          return brand === filters.brand;
        })
      }
    }
  });

  return <div className={classes.catListing}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={4} lg={3}>
        <Sidenav id={id} name={name} subcategories={filteredProducts} brands={brands} />
      </Grid>
      <Grid item xs={12} md={8} lg={9}>
        <CategoriesGrid id={id} name={name} products={products} subcategories={wpChildren.nodes} />
      </Grid>
    </Grid>
  </div>
}

export default connector(Category);