import React from "react";
import { useSelector } from "react-redux";

import Picker from "./Picker";
import Comparison from "./Comparison";

export default function Compare() {
  const { showComparison } = useSelector((state) => state.CompareAppSlice);

  if (!showComparison) {
    return <Picker />;
  }

  if (showComparison) {
    return <Comparison />;
  }

  return null;
}
