import activeCross from "../../../images/active-brand.png";

export default (theme) => ({
  sideNav: {
    position: "relative",
    "& .categories": {
      overflow: "hidden",
      backgroundColor: "white",
      marginBottom: 80,
      "& h3": {
        fontSize: 24,
        lineHeight: 1,
        letterSpacing: "0.016em",
        fontFamily: theme.headerFont,
        fontWeight: "bold",
        padding: "15px 20px 20px",
        margin: 0,
      },
      "& .current-category": {
        display: "block",
        padding: 20,
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: "0.016em",
        fontFamily: theme.headerFont,
        color: "rgba(15,15,15,0.5)",
        textDecoration: "none",
        position: "relative",
        boxShadow: "0 1px 2px rgba(0,0,0,0.16)",
        "&::after": {
          content: '""',
          width: 12,
          height: 12,
          transform: "rotate(-45deg)",
          position: "absolute",
          top: 22,
          right: 23,
          transformOrigin: "50%",
          borderRight: "3px solid " + theme.palette.secondary.main,
          borderBottom: "3px solid " + theme.palette.secondary.main,
        },
      },
      "& .children": {
        padding: 10,
        margin: 0,
        "& li": {
          display: "block",
          "& a": {
            display: "block",
            padding: 10,
            fontSize: 14,
            fontFamily: "Overpass",
            lineHeight: 1.5,
            letterSpacing: "0.008em",
            textDecoration: "none",
            color: theme.palette.primary.main,
            paddingRight: 40,
            position: "relative",
            "& span": {
              color: "rgba(15,15,15,.35)",
              fontFamily: theme.headerFont,
              fontWeight: "bold",
              position: "absolute",
              top: 10,
              right: 20,
            },
          },
          "&.active a, &.active a span": {
            color: theme.palette.secondary.main,
          },
        },
      },
    },
    "& h3.filters": {
      fontSize: 24,
      lineHeight: 1,
      letterSpacing: "0.016em",
      fontFamily: theme.headerFont,
      fontWeight: "bold",
      margin: 0,
      marginBottom: 20,
    },
    "& .range, & .brands": {
      backgroundColor: "white",
      marginBottom: 24,
      "& h4": {
        fontFamily: theme.headerFont,
        lineHeight: 1.5,
        letterSpacing: "0.016em",
        fontSize: 16,
        fontWeight: "bold",
        padding: 20,
        margin: 0,
      },
      "& button": {
        border: "none",
        backgroundColor: "transparent",
        display: "block",
        padding: "10px 20px",
        fontSize: 14,
        fontFamily: "Overpass",
        lineHeight: 1.5,
        letterSpacing: "0.008em",
        textDecoration: "none",
        color: theme.palette.primary.main,
        width: "100%",
        textAlign: "left",
        position: "relative",
        "&.is-active": {
          color: theme.palette.secondary.main,
          "&::before": {
            content: '""',
            width: 13,
            height: 13,
            backgroundImage: "url(" + activeCross + ")",
            display: "inline-block",
            marginRight: 10,
            verticalAlign: "text-top",
          },
        },
      },
    },
    "& .range": {
      "& .inputs": {
        display: "flex",
        padding: "0 8px 24px",
        alignItems: "center",
        "& span": {
          fontSize: 12,
          fontFamily: "Overpass",
          margin: "0 12px",
        },
        "& input": {
          fontSize: 12,
          padding: "10px 12px",
          letterSpacing: "0.016em",
          fontFamily: "Overpass",
          border: "none",
          borderRadius: 18,
          outline: "none",
          boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
          width: 75,
          backgroundColor: "#f5f5f5",
          margin: 0,
        },
      },
    },
    "& button.reset": {
      display: "table",
      margin: "0 auto",
      backgroundColor: "white",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontFamily: theme.headerFont,
      fontSize: 16,
      lineHeight: 1.5,
      borderRadius: 15,
      boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
      textDecoration: "none",
      padding: "5px 20px",
      border: "none",
      transition: "background-color .3s ease-in-out, color .3s ease-in-out",
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
});
