import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

import Breadcrumb from "../components/Breadcrumb";
import Category from "../components/Products/Pages/Category";
import SubCategory from "../components/Products/Pages/Subcategory";

class Page extends Component {
  constructor(props) {
    super(props);

    this.categoriesWithProducts = this.categoriesWithProducts.bind(this);
  }

  categoriesWithProducts() {
    const allProducts = this.props.data.allWpProduct.nodes;
    const category = this.props.data.wpProductCategory;
    const subcategories = this.props.data.wpProductCategory.wpChildren.nodes;

    return {
      ...category,
      wpChildren: {
        nodes: subcategories.map((subcategory) => {
          const { id } = subcategory;
          const products = allProducts.filter((product) =>
            product.productCategories.nodes
              .map((category) => category.id)
              .includes(id)
          );
          subcategory.products = { nodes: [...products] };
          return subcategory;
        }),
      },
    };
  }

  subcategoriesWithProducts() {
    const allProducts = this.props.data.allWpProduct.nodes;
    const category = this.props.data.wpProductCategory;
    const siblings =
      this.props.data.wpProductCategory.wpParent.node &&
      this.props.data.wpProductCategory.wpParent.node.wpChildren.nodes;
    const subsubcategories = this.props.data.wpProductCategory.wpChildren.nodes;

    const { id } = category;
    const products = allProducts.filter((product) =>
      product.productCategories.nodes
        .map((category) => category.id)
        .includes(id)
    );

    return {
      ...category,
      products,
      subsubcategories: subsubcategories.map((subsubcategory) => {
        const { id } = subsubcategory;
        const products = allProducts.filter((product) =>
          product.productCategories.nodes
            .map((category) => category.id)
            .includes(id)
        );
        return {
          ...subsubcategory,
          products: { nodes: [...products] },
        };
      }),
      siblings:
        siblings &&
        siblings.map((subcategory) => {
          const { id } = subcategory;
          const products = allProducts.filter((product) =>
            product.productCategories.nodes
              .map((category) => category.id)
              .includes(id)
          );
          subcategory.products = { nodes: [...products] };
          return subcategory;
        }),
    };
  }

  render() {
    const isCategory = !this.props.data.wpProductCategory.wpParent;
    const isSubcategory =
      !!this.props.data.wpProductCategory.wpParent &&
      !!this.props.data.wpProductCategory.wpChildren;

    const { name, slug, uri } = this.props.data.wpProductCategory;
    const brands = this.props.data.allWpBrand;

    return (
      <Layout
        meta={{
          ...this.props.data.wpPage.Meta,
          canonical: this.props.data.wpProductCategory.uri,
        }}
        path={this.props.data.wpProductCategory.uri}
        cta={"a"}
        themeOptions={this.props.data.wp.themeOptions}
      >
        <Breadcrumb
          type="category"
          current={{
            title: name,
            slug,
            uri,
          }}
          parent={
            this.props.data.wpProductCategory.wpParent &&
            this.props.data.wpProductCategory.wpParent.node
          }
        />
        {isCategory && (
          <Category
            {...this.categoriesWithProducts()}
            default={
              this.props.data.wp.themeOptions.ThemeOptions.defaultImages
                .categoryImage
            }
            brands={brands}
          />
        )}
        {isSubcategory && (
          <SubCategory {...this.subcategoriesWithProducts()} brands={brands} />
        )}
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String) {
    allWpProduct(sort: { fields: modifiedGmt }) {
      nodes {
        id
        title
        link
        modifiedGmt(formatString: "x")
        product {
          code
          description
          tradePrice
          rrpPrice
          rrpPricePublic
          memberPrice
          order
          productGallery {
            image
          }
          brand {
            slug
          }
          techSpec {
            title
            value
          }
          downloads {
            title
            file
          }
          features {
            feature
          }
        }
        productCategories {
          nodes {
            id
          }
        }
      }
    }
    wpProductCategory(id: { eq: $id }) {
      id
      name
      slug
      uri
      productCategoryExtras {
        categoryImage {
          sourceUrl
        }
      }
      wpChildren {
        nodes {
          id
          name
          uri
          productCategoryExtras {
            categoryImage {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            categoryIcon {
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      wpParent {
        node {
          id
          name
          slug
          uri
          wpChildren {
            nodes {
              id
              name
              uri
            }
          }
        }
      }
    }
    wpPage(uri: { eq: "/" }) {
      id
      Meta {
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
    }
    allWpBrand {
      edges {
        node {
          id
          name
          slug
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          defaultImages {
            categoryImage {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
