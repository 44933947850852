import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { hideComparison } from "./app";
import { isLoggedIn } from "../../services/auth";
import { isBrowser } from "../../helpers";

import styles from "./Comparison.styles";
import ComparisonProduct from "./ComparisonProduct";

const useStyles = makeStyles((theme) => styles(theme));

export default function Comparison() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [authentication, setAuthentication] = useState(undefined);
  const [group, setGroup] = useState();

  useEffect(() => {
    isBrowser && authentication === undefined && handleAuthentication();
  }, []); //eslint-disable-line

  async function handleAuthentication() {
    const response = await isLoggedIn();

    if (response.data.errors) {
      setAuthentication(false);
    } else {
      setAuthentication(response.data.data.user);
      const group = response.data.data.user.userGroup.group.slug;

      if (group) {
        setGroup(group);
      }
    }
  }

  const { products } = useSelector((state) => state.CompareAppSlice);
  const { showComparison } = useSelector((state) => state.CompareAppSlice);

  const firstProduct = products[0];
  const secondProduct = products[1];

  return (
    <div className={classes.comparisonModal}>
      {showComparison && (
        <style>{"body{max-height: 100vh;overflow:hidden;}"}</style>
      )}

      <button onClick={() => dispatch(hideComparison())} className="close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
        >
          <g
            id="Group_2266"
            data-name="Group 2266"
            transform="translate(-1309.822 -66.822)"
          >
            <g
              id="Rectangle_7334"
              data-name="Rectangle 7334"
              transform="translate(1309.822 66.822)"
              fill="#fff"
              stroke="#c8102e"
              stroke-width="4"
            >
              <rect width="40" height="40" rx="8" stroke="none" />
              <rect x="2" y="2" width="36" height="36" rx="6" fill="none" />
            </g>
            <path
              id="compress-alt"
              d="M33.626,2.673a2.294,2.294,0,0,0-3.257,0l-13.281,13.3V12.33a2.294,2.294,0,0,0-4.588,0V21.5A2.329,2.329,0,0,0,14.794,23.8h9.175a2.294,2.294,0,0,0,0-4.588H20.322L33.626,5.93a2.294,2.294,0,0,0,0-3.257Z"
              transform="translate(1312.07 67.292)"
              fill="#c8102e"
            />
          </g>
        </svg>
      </button>

      <h2>The right product</h2>
      <p>Your two product comparisons</p>

      <div className="products">
        {firstProduct && (
          <ComparisonProduct product={firstProduct} group={group} />
        )}

        {secondProduct && (
          <ComparisonProduct product={secondProduct} group={group} />
        )}
      </div>
    </div>
  );
}
