export default (theme) => ({
  comparisonModal: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    maxHeight: "98%",
    overflow: "auto",
    zIndex: 9999,
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    boxShadow: "0px -16px 16px rgba(0, 0, 0, 0.4)",
    padding: "23px 0 23px 16px",
    [theme.breakpoints.up("lg")]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      paddingRight: 16,
    },
    "&:before": {
      content: "''",
      display: "block",
      width: 166,
      height: 5,
      margin: "0 auto",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 3,
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    "& .close": {
      margin: "12px 16px 48px auto",
      display: "block",
      backgroundColor: "transparent",
      padding: 0,
      border: "none",
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        marginBottom: 0,
      },
      [theme.breakpoints.up(1206)]: {
        marginRight: "calc(50% - 603px)",
      },
    },
    "& > h2": {
      margin: 0,
      marginBottom: 8,
      fontFamily: "'Bebas Neue', sans-serif",
      fontSize: 56,
      letterSpacing: "-0.02em",
      lineHeight: 1,
      color: theme.palette.primary.main,
      fontWeight: "normal",
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1206,
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 80,
      },
    },
    "& > p": {
      fontFamily: "'Roboto Condensed', sans-serif",
      fontSize: 24,
      lineHeight: 1,
      color: theme.palette.primary.main,
      borderBottom: "2px solid #ededed",
      paddingBottom: 10,
      marginBottom: 26,
      letterSpacing: "0.01em",
      width: "calc(100% - 16px)",
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1206,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    "& .products": {
      whiteSpace: "nowrap",
      overflowX: "scroll",
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1206,
        marginLeft: "auto",
        marginRight: "auto",
        overflowX: "hidden",
      },
      "& .product": {
        whiteSpace: "normal",
        minWidth: 255,
        width: "calc(50% - 40px)",
        marginRight: 40,
        display: "inline-block",
        verticalAlign: "top",
        [theme.breakpoints.up("lg")]: {
          width: "calc(50% - 10px)",
          marginRight: 20,
          "&:nth-of-type(2)": {
            margtinRight: 0,
          },
        },
        "& .remove": {
          backgroundColor: "transparent",
          border: "none",
          padding: 0,
          float: "right",
          color: theme.palette.secondary.main,
          fontFamily: "'Overpass', sans-serif",
          fontSize: 16,
          letterSpacing: "0.02em",
          "& svg": {
            marginRight: 10,
          },
        },
        "& .prod-intro": {
          display: "block",
          [theme.breakpoints.up("lg")]: {
            marginBottom: 32,
          },
          "& img": {
            height: 85,
            width: "auto",
            marginBottom: 10,
            marginLeft: 45,
            [theme.breakpoints.up("lg")]: {
              height: 135,
              display: "inline-block",
              verticalAlign: "middle",
              marginLeft: "0",
              marginRight: 30,
              marginBottom: 0,
            },
          },
          "& .data": {
            [theme.breakpoints.up("lg")]: {
              width: "calc(100% - 260px)",
              display: "inline-block",
              verticalAlign: "middle",
            },
          },
          "& .sku": {
            borderTop: "2px solid #ededed",
            margin: 0,
            paddingTop: 16,
            paddingBottom: 12,
            display: "block",
            fontSize: 14,
            letterSpacing: "0.02em",
            lineHeight: "24px",
            fontFamily: "'Bebas Neue', sans-serif",
            color: "#0f0f0f",
            [theme.breakpoints.up("lg")]: {
              paddingTop: 0,
              borderTop: 0,
              fontSize: 16,
              paddingBottom: 6,
            },
          },
          "& .title": {
            marginTop: 0,
            fontFamily: "'Bebas Neue', sans-serif",
            fontSize: 18,
            color: theme.palette.primary.main,
            lineHeight: "24px",
            marginBottom: 14,
            minHeight: 72,
            fontWeight: "normal",
            [theme.breakpoints.up("lg")]: {
              minHeight: 0,
              marginBottom: 0,
            },
          },
        },
        "& .actions": {
          marginBottom: 70,
          "& .enquire": {
            padding: "14px 12px",
            fontFamily: "'Bebas Neue', sans-serif",
            color: "white",
            fontSize: 18,
            letterSpacing: "0.02em",
            borderRadius: 50,
            border: "none",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            backgroundColor: theme.palette.secondary.main,
            marginBottom: 20,
            width: 210,
            textAlign: "center",
            display: "block",
            textDecoration: "none",
            [theme.breakpoints.up("lg")]: {
              display: "inline-block",
              width: "auto",
              padding: "14px 32px",
              marginRight: 20,
            },
          },
          "& .search": {
            padding: "14px 12px",
            fontFamily: "'Bebas Neue', sans-serif",
            backgroundColor: "white",
            fontSize: 18,
            letterSpacing: "0.02em",
            borderRadius: 50,
            border: "none",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            color: theme.palette.secondary.main,
            marginBottom: 20,
            width: 210,
            textAlign: "center",
            display: "block",
            textDecoration: "none",
            "& svg": {
              marginRight: 5,
            },
            [theme.breakpoints.up("lg")]: {
              display: "inline-block",
              width: "auto",
              padding: "14px 32px",
              marginRight: 20,
            },
          },
          "& .add-wishlist": {
            fontFamily: "'Bebas Neue', sans-serif",
            backgroundColor: "transparent",
            border: "none",
            fontSize: 18,
            color: theme.palette.secondary.main,
            letterSpacing: "0.02em",
            padding: "5px 20px",
            [theme.breakpoints.up("lg")]: {
              display: "inline-block",
              width: "auto",
              padding: "14px 0px",
              marginRight: 0,
            },
          },
          "&.border": {
            marginBottom: 70,
            border: "2px solid #ededed",
            padding: 20,
            borderRadius: 40,
            paddingBottom: 60,
            marginTop: 50,
            [theme.breakpoints.up("lg")]: {
              padding: "40px 20px",
              "& .enquire, & .search, & .add-wishlist": {
                marginBottom: 10,
              },
            },
          },
        },
        "& .price-list, & .overview, & .tech-spec, & .downloads": {
          "& h3": {
            borderBottom: "2px solid #ededed",
            paddingBottom: 5,
            marginBottom: 16,
            fontFamily: "'Roboto Condensed', sans-serif",
            fontSize: 18,
            letterSpacing: "0.01em",
            lineHeight: 1.2,
            color: theme.palette.primary.main,
            [theme.breakpoints.up("lg")]: {
              fontSize: 24,
              marginTop: 32,
            },
          },
          "& p": {
            fontSize: 16,
            color: "#0f0f0f",
            letterSpacing: "0.02em",
            lineHeight: "28px",
            marginBottom: 20,
          },
          "& ul": {
            paddingLeft: 60,
            marginBottom: 22,
            "& li": {
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 10,
              position: "relative",
              display: "block",
              "&:before": {
                content: "''",
                backgroundColor: theme.palette.secondary.main,
                width: 16,
                height: 14,
                display: "block",
                position: "absolute",
                left: -30,
                top: 3,
                clipPath:
                  "polygon(0 50%, 3px 0, calc(100% - 3px) 0, 100% 50%, calc(100% - 3px) 100%, 3px 100%)",
              },
            },
          },
          "& table": {
            marginBottom: 32,
            "& tr:nth-of-type(odd)": {
              backgroundColor: "#ededed",
            },
            [theme.breakpoints.up("lg")]: {
              "& td": {
                fontSize: 16,
                padding: "24px 30px",
              },
            },
          },
          "& .MuiTableCell-root": {
            borderBottom: 0,
          },
        },
        "& .downloads": {
          "& a": {
            padding: "14px 12px",
            fontFamily: "'Bebas Neue', sans-serif",
            backgroundColor: "white",
            fontSize: 18,
            letterSpacing: "0.02em",
            borderRadius: 50,
            border: "none",
            boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
            color: theme.palette.secondary.main,
            marginBottom: 20,
            width: 210,
            textAlign: "center",
            display: "block",
            textDecoration: "none",
          },
        },
      },
    },
  },
});
