import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { hideComparison, removeProduct } from "./app";
import { addToWishlist, removeFromWishlist } from "../../app/actions";
import { priceFormatter } from "../../helpers";

import Link from "../Link";
//import styles from "./Comparison.styles";

export default function ComparisonProduct({ product, group }) {
  //const classes = useStyles();
  const dispatch = useDispatch();

  const wishlist = useSelector((state) => state.WishlistSettings);

  const {
    rrpPricePublic,
    rrpPrice,
    memberPrice,
    tradePrice,
    description,
    techSpec,
    downloads,
    features,
  } = product.product;

  return (
    <div className="product">
      <button
        onClick={() => {
          dispatch(removeProduct(product.id));
          dispatch(hideComparison());
        }}
        className="remove"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12.169"
          height="12.17"
          viewBox="0 0 12.169 12.17"
        >
          <path
            d="M3258.992,750.544a2.067,2.067,0,0,1-1.472-.61l-2.531-2.53-2.53,2.53a2.083,2.083,0,0,1-2.944-2.946l2.529-2.53-2.529-2.53a2.083,2.083,0,0,1,2.944-2.946l2.53,2.53,2.531-2.53a2.083,2.083,0,0,1,2.946,2.946l-2.531,2.53,2.531,2.53a2.083,2.083,0,0,1-1.473,3.556Zm-4-3.978Z"
            transform="translate(-3248.905 -738.374)"
            fill="#c8102e"
          />
        </svg>
        Remove
      </button>
      <div className="prod-intro">
        <img
          src={product.product.productGallery[0].image}
          width={200}
          height={200}
          alt={``}
        />
        <div className="data">
          <p className="sku">{product.product.code}</p>
          <h3 className="title">{product.title}</h3>
        </div>
      </div>
      <div className="actions">
        <Link
          className="enquire"
          to="/enquiry"
          state={{
            product: {
              id: product.id,
              title: product.title,
              code: product.product.code,
            },
          }}
        >
          Enquire Now
        </Link>
        <Link className="search" to="/distributors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="15.614"
            viewBox="0 0 11 15.614"
          >
            <path
              id="Subtraction_2"
              data-name="Subtraction 2"
              d="M-1619.5,1810.614h0c-.057-.073-1.4-1.82-2.75-3.931a31.046,31.046,0,0,1-1.891-3.341,7.355,7.355,0,0,1-.859-2.86,5.434,5.434,0,0,1,1.611-3.875A5.474,5.474,0,0,1-1619.5,1795a5.474,5.474,0,0,1,3.889,1.605,5.435,5.435,0,0,1,1.611,3.875,7.356,7.356,0,0,1-.859,2.86,31.042,31.042,0,0,1-1.891,3.341c-1.347,2.111-2.693,3.858-2.75,3.931Zm0-12.983a3.3,3.3,0,0,0-3.3,3.288,3.3,3.3,0,0,0,3.3,3.289,3.3,3.3,0,0,0,3.3-3.289A3.3,3.3,0,0,0-1619.5,1797.63Z"
              transform="translate(1625 -1795)"
              fill="#c8102e"
            />
          </svg>
          Search for distributors
        </Link>
        <button
          className="add-wishlist"
          onClick={() => {
            if (wishlist.includes(product.id)) {
              dispatch(removeFromWishlist(product.id));
            } else {
              dispatch(addToWishlist(product.id));
            }
          }}
        >
          Add to wishlist
        </button>
      </div>

      {(group || rrpPricePublic) && (
        <div className="price-list">
          <h3>Price list</h3>
          <Table>
            <TableBody>
              {rrpPricePublic && !group && (
                <TableRow>
                  <TableCell>RRP</TableCell>
                  <TableCell>
                    <strong>{priceFormatter(rrpPricePublic)}</strong>
                  </TableCell>
                </TableRow>
              )}

              {memberPrice && group === "member" && (
                <TableRow>
                  <TableCell>Trade</TableCell>
                  <TableCell>
                    <strong>{priceFormatter(memberPrice)}</strong>
                  </TableCell>
                </TableRow>
              )}

              {tradePrice && (group === "trade" || group === "member") && (
                <TableRow>
                  <TableCell>Member</TableCell>
                  <TableCell>
                    <strong>{priceFormatter(tradePrice)}</strong>
                  </TableCell>
                </TableRow>
              )}

              {rrpPrice && (group === "trade" || group === "member") && (
                <TableRow>
                  <TableCell>RRP</TableCell>
                  <TableCell>
                    <strong>{priceFormatter(rrpPrice)}</strong>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      )}
      <div className="overview">
        <h3>Overview</h3>
        {ReactHtmlParser(description)}

        {features && (
          <ul>
            {features.map((feature, index) => (
              <li key={index}>{feature.feature}</li>
            ))}
          </ul>
        )}
      </div>

      {techSpec && (
        <div className="tech-spec">
          <h3>Tech Spec</h3>
          <Table>
            <TableBody>
              {techSpec.map((spec) => (
                <TableRow key={product.title + spec.title}>
                  <TableCell>{spec.title}</TableCell>
                  <TableCell>
                    <strong>{spec.value}</strong>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {downloads && (
        <div className="downloads">
          <h3>Downloads</h3>
          {downloads.map((file) => (
            <Link to={file.file}>
              <span>{ReactHtmlParser(file.title)}</span>
            </Link>
          ))}
        </div>
      )}

      <div className="actions border">
        <Link
          className="enquire"
          to="/enquiry"
          state={{
            product: {
              id: product.id,
              title: product.title,
              code: product.product.code,
            },
          }}
        >
          Enquire Now
        </Link>
        <Link className="search" to="/distributors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="15.614"
            viewBox="0 0 11 15.614"
          >
            <path
              id="Subtraction_2"
              data-name="Subtraction 2"
              d="M-1619.5,1810.614h0c-.057-.073-1.4-1.82-2.75-3.931a31.046,31.046,0,0,1-1.891-3.341,7.355,7.355,0,0,1-.859-2.86,5.434,5.434,0,0,1,1.611-3.875A5.474,5.474,0,0,1-1619.5,1795a5.474,5.474,0,0,1,3.889,1.605,5.435,5.435,0,0,1,1.611,3.875,7.356,7.356,0,0,1-.859,2.86,31.042,31.042,0,0,1-1.891,3.341c-1.347,2.111-2.693,3.858-2.75,3.931Zm0-12.983a3.3,3.3,0,0,0-3.3,3.288,3.3,3.3,0,0,0,3.3,3.289,3.3,3.3,0,0,0,3.3-3.289A3.3,3.3,0,0,0-1619.5,1797.63Z"
              transform="translate(1625 -1795)"
              fill="#c8102e"
            />
          </svg>
          Search for distributors
        </Link>
        <button
          className="add-wishlist"
          onClick={() => {
            if (wishlist.includes(product.id)) {
              dispatch(removeFromWishlist(product.id));
            } else {
              dispatch(addToWishlist(product.id));
            }
          }}
        >
          Add to wishlist
        </button>
      </div>
    </div>
  );
}
