export default (theme) => ({
  compare: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "white",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    boxShadow: "0px -16px 16px rgba(0, 0, 0, 0.4)",
    padding: "23px 16px",
    zIndex: 9999,
    [theme.breakpoints.up("md")]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    "& .wrapper": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: 1206,
      marginLeft: "auto",
      marginRight: "auto",
      "& > h2": {
        flex: "0 0 100%",
        textAlign: "center",
        fontFamily: "'Roboto Condensed', sans-serif",
        fontSize: 18,
        letterSpacing: "0.008em",
        color: "#1B365D",
        fontWeight: "normal",
        marginBottom: 16,
        marginTop: 0,
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        "&:before": {
          display: "block",
          content: "''",
          backgroundColor: theme.palette.secondary.main,
          height: 5,
          width: 166,
          borderRadius: 3,
          marginBottom: 19,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
    },
    "& .product": {
      border: "1px solid rgba(15,15,15,0.3)",
      boxShadow: "0 8px 16px rgba(0,0,0,0.16)",
      backgroundColor: "white",
      flex: "0 0 calc(50% - 5px)",
      maxWidth: "calc(50% - 5px)",
      padding: 15,
      display: "block",
      marginBottom: 20,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(((100% - 300px) / 2) - 24px)",
        maxWidth: "calc(((100% - 300px) / 2) - 24px)",
        marginBottom: 0,
        padding: 24,
        position: "relative",
      },
      "&:after": {
        clear: "both",
        content: "''",
        display: "table",
      },
      "& button": {
        border: "none",
        backgroundColor: "transparent",
        padding: 0,
        lineHeight: 1,
        marginLeft: "auto",
        marginBottom: -5,
        display: "block",
        [theme.breakpoints.up("md")]: {
          position: "absolute",
          top: 24,
          right: 24,
          marginBottom: 0,
          marginLeft: 0,
        },
      },
      "& img": {
        height: 80,
        width: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        display: "block",
        [theme.breakpoints.up("md")]: {
          width: 120,
          height: "auto",
          marginTop: "auto",
          marginRight: "auto",
          display: "inline-block",
          borderRight: "1px solid #ededed",
          verticalAlign: "middle",
        },
      },
      "& .data": {
        [theme.breakpoints.up("md")]: {
          display: "inline-flex",
          verticalAlign: "middle",
          paddingLeft: 20,
          flexDirection: "column",
          justifyContent: "center",
          maxWidth: "calc(100% - 150px)",
        },
      },
      "& .sku": {
        marginTop: 0,
        paddingTop: 16,
        borderTop: "1px solid #ededed",
        fontFamily: "'Bebas Neue', sans-serif",
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: "0.02em",
        lineHeight: "24xp",
        color: "#0f0f0f",
        [theme.breakpoints.up("md")]: {
          borderTop: "none",
          paddingTop: 0,
        },
      },
      "& .title": {
        fontFamily: "'Bebas Neue', sans-serif",
        fontSize: 16,
        fontWeight: "bold",
        lineHeight: 1,
        letterSpacing: "0.02em",
        color: "#1b365d",
        marginBottom: 16,
        marginTop: 0,
        [theme.breakpoints.up("md")]: {},
      },
    },
    "& .add": {
      flex: "0 0 calc(50% - 5px)",
      maxWidth: "calc(50% - 5px)",
      border: "3px dashed rgba(15,15,15,0.3)",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "'Bebas Neue', sans-serif",
      color: "rgba(15,15,15,0.3)",
      fontSize: 18,
      letterSpacing: "0.02em",
      fontWeight: "bold",
      marginBottom: 20,
      [theme.breakpoints.up("md")]: {
        flex: "0 0 calc(((100% - 300px) / 2) - 24px)",
        maxWidth: "calc(((100% - 300px) / 2) - 24px)",
        marginBottom: 0,
      },
      "& svg": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          marginBottom: 5,
          display: "block",
        },
      },
    },
    "& .actions": {
      display: "flex",
      flexDirection: "row-reverse",
      flex: "0 0 100%",
      flexWrap: "wrap",
      [theme.breakpoints.up("md")]: {
        flex: "0 0 300px",
        maxWidth: "300px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "auto",
        marginBottom: "auto",
      },
      "& > h2": {
        display: "none",
        fontFamily: "'Roboto Condensed', sans-serif",
        fontSize: 24,
        letterSpacing: "0.008em",
        color: "#1B365D",
        fontWeight: "normal",
        marginBottom: 20,
        flex: "0 0 100%",
        maxWidth: "100%",
        marginTop: 0,
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
      "& .compare": {
        padding: "14px 32px",
        fontFamily: "'Bebas Neue', sans-serif",
        color: "white",
        fontSize: 18,
        letterSpacing: "0.02em",
        borderRadius: 50,
        border: "none",
        backgroundColor: theme.palette.secondary.main,
      },
      "& .clear": {
        border: "none",
        backgroundColor: "transparent",
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 16,
        letterSpacing: "0.02em",
        color: theme.palette.secondary.main,
        fontFamily: "'Overpass', sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        [theme.breakpoints.up("md")]: {
          marginRight: 0,
        },
        "& span": {
          marginLeft: 10,
        },
      },
    },
  },
});
