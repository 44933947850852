import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { sort } from "fast-sort";

import Sidenav from "../Components/Sidenav";
import ProductsGrid from "../Components/Grid";
import Pagination from "../Components/Pagination";
import Compare from "../../Compare";

import {
  setFilter,
  setPage,
  setSort,
  setItemsToShow,
} from "../../../app/actions";
import { isLoggedIn } from "../../../services/auth";
import { makeStyles } from "@material-ui/styles";
import styles from "./SubCategory.styles";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  filters: state.ShopSettings.filters,
  sortSelected: state.ShopSettings.sort,
  itemsToShow: state.ShopSettings.itemsToShow,
  page: state.ShopSettings.page,
});

const mapDispatchToProps = (dispatch) => ({
  setSort: (sort) => dispatch(setSort(sort)),
  setItemsToShow: (itemsToShow) => dispatch(setItemsToShow(itemsToShow)),
  setPage: (page) => dispatch(setPage(page)),
  setFilter: (prevFilters, filter) => dispatch(setFilter(prevFilters, filter)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

function Subcategory({
  id,
  name,
  uri,
  siblings,
  subsubcategories,
  wpParent,
  products,
  page,
  setPage,
  brands,
  filters,
  setFilter,
  sortSelected,
  setSort,
  itemsToShow,
  setItemsToShow,
}) {
  const classes = useStyles();

  const [authenticated, setAuthentication] = useState(undefined); //eslint-disable-line
  const [group, setGroup] = useState(null); //eslint-disable-line

  useEffect(() => {
    setPage(1);
    const urlParams = new URLSearchParams(window.location.search);
    setFilter({ ...filters, brand: urlParams.get('brand') || ""});

    isLoggedIn().then((response) => {
      if (response.data.errors) {
        setAuthentication(false);
      } else {
        setAuthentication(response.data.data.user);

        const group = response.data.data.user.userGroup.group.slug;

        if (group) {
          setGroup(group);
        }
      }
    });
  }, [uri]); //eslint-disable-line

  function setItemsToShowHandler(itemsToShow) {
    setItemsToShow(itemsToShow === "all" ? "all" : Number(itemsToShow));
    setPage(1);
  }

  const filteredProducts = products
    .filter((product) => {
      if (!filters.brand) return true;
      const productBrand = product.product.brand && product.product.brand.slug;
      return filters.brand === productBrand;
    })
    .filter((product) => {
      if (!product.product.tradePrice) return true;
      if (filters.min > product.product.tradePrice) return false;
      if (filters.max < product.product.tradePrice) return false;
      return product;
    });

  const sortedProducts = sort(filteredProducts).by([
    { asc: (product) => sortSelected === "sku" && product.product.code },
    {
      desc: (product) =>
        sortSelected === "recommended" && product.product.order,
    },
    { asc: (product) => product.title },
  ]);

  const pagetoShow =
    itemsToShow === "all"
      ? sortedProducts
      : sortedProducts.slice(
          page * itemsToShow - itemsToShow,
          page * itemsToShow
        );

  return (
    <div className={classes.subCatPage}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={3}>
          <Sidenav
            id={id}
            uri={uri}
            name={name}
            siblings={siblings}
            subsubcategories={subsubcategories}
            parent={wpParent.node}
            brands={brands}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9} style={{ paddingBottom: 72 }}>
          <div className="top-bar">
            <div className="totals">
              <p>{filteredProducts.length} items</p>
            </div>
            <div className="orderBy">
              <label htmlFor="orderBy">Order by:</label>
              <select
                name="orderBy"
                defaultValue={sortSelected}
                onBlur={(e) => setSort(e.target.value)}
              >
                <option value="recommended">Recommended</option>
                <option value="sku">WPRO Product Number</option>
              </select>
            </div>
            <div className="itemToShow">
              <label htmlFor="itemToShow">Results per page:</label>
              <select
                name="itemsToShow"
                defaultValue={itemsToShow}
                onBlur={(e) => setItemsToShowHandler(e.target.value)}
              >
                <option value="9">9</option>
                <option value="18">18</option>
                <option value="36">36</option>
                <option value="all">All</option>
              </select>
            </div>
          </div>
          <ProductsGrid products={pagetoShow} />
          <Pagination products={filteredProducts} num={itemsToShow} />
        </Grid>
      </Grid>
      <Compare />
    </div>
  );
}

export default connector(Subcategory);
